export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/';
export const LOGBOOK = '/logbook';
export const ACCOUNT = '/account';
export const ADD = '/add';
export const EDIT = '/qso';
export const SETTINGS = '/settings';
export const PASSWORD_FORGET = '/pw-forget';
export const MAP = '/map';
export const STATS = '/stats';
export const REPORTER = '/pskreporter';
export const DOWNLOAD = '#download';

